.three-container {
    display:flex;
    background-color: #DBDBDB;
    align-items: center;
    padding-top: 180px;
}

.title-nav {
    flex:1;
    display:flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally within the title-nav */
    justify-content: center;
}
 
.text-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.three {
    padding-right: 15px;
}

.ways {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(2rem, 3.59vw, 69px);
    font-size: 69px;
    font-weight: 700;
    text-align: center;
}

.ways2 {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(1rem, 1.4vw, 27px);
    font-size: 27px;
    font-weight: 400;
    text-align: center;
    white-space: pre-line;
}

.nav-buttons {
    display:flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
}

.nav-buttons div {
    margin: 0 -15px; /* Adjust the margin as needed for the desired spacing */
}


.image-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .timage {
    flex: 1; /* Allow the images to take up all available space */
    background-color: #707070;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .timage img:hover {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

  .inactive {
    opacity: 0.49;
    transition: opacity 0.5s ease-in-out;
  }
 


@media (max-width: 1700px) {
    .three-container {
        flex-direction: column;
        padding-top: 100px;
    }

    .three img {
        width: 70%; /* Set the width to 70% of the original size */
        height: auto; /* Maintain the aspect ratio */
        /* margin-left: 7px; */
    }

    .three {
        padding-right: 0px;
    }

    .ways {
        font-size: 42px;        
    }
    
    .ways2 {
        font-size: 20px;
    }

    .image-container {
        display: flex;
        flex-wrap: column;
        /* justify-content: space-between; */
        /* grid-template-columns: repeat(2, 1fr);  */
        
    }

    .timage {  
        flex: 50%; 
    }

    .timage img {
        width: 31vw;
    }
}

/* Media query for smaller width, adjust the max-width as needed */
@media (max-width: 768px) { /* Adjust this value based on when you want to hide the carousel */
    .three-container {
        /* display: none; */
        padding-top: 60px;
    }

    .three img {
        width: 50%; /* Set the width to 70% of the original size */
        height: auto; /* Maintain the aspect ratio */
        /* margin-left: 7px; */
    }

    .three {
        margin-left: 60px;
        margin-right: -70px;
        
    }

    .ways {
        font-size: 32px;  
        width: 90%;      
    }
    
    .ways2 {
        font-size: 16px;
        width: 90%;
    }
    
}


  
  

