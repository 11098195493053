/******** Main banner ********/


/* .main-banner {
    background-image: url("/public/pics/banner1.png");
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
    height: 46vw;
} */

.mb-text-container {
    padding-top: 13vw;
    margin-left: 15vw;
}

.mb-title {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(2rem, 5.2vw, 100px);
    font-weight: 700;
    line-height: 1;
}

.mb-text {
    font-family: "Avenir", sans-serif;
    font-size: clamp(1rem, 1.67vw, 32px);
    width:35%;
}

.commit-btn {
    font-family: "poppins", sans-serif;
    font-size: clamp(1rem, 1.4vw, 24px);
    font-weight: 700;
    padding: 15px 35px 15px 35px;
    border: none;
    border-radius: 10px;
    background-color: #EE3E2F;
    color: white;
}

.commit-btn:hover {
    background-color: #d1291a;
}

/* Gap property can add anywhere */
.universal-gap {
    margin-bottom: 40px;
}

/* Commit 4 Compassion */

.compassion-container {
    background-color: #DBDBDB;
    height: 38vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center; /* This aligns content vertically in flex comparted to jc that does horizontally */
}

.cc-title {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(2rem, 3.75vw, 72px);
    font-weight: 700;
    line-height: 1;
}

.cc-text {
    font-family: "Avenir", sans-serif;
    font-size: clamp(1rem, 1.46vw, 28px);
    line-height: 1.8;
    width: 69%;
}

/* 3 Slide Carousel */

.carousel-container {
    background-color: #DBDBDB;
    height: 31.6vw;
}

@media (max-width:1100px) {
    .universal-gap {
        margin-bottom: 20px;
    }

    .main-banner {
        height: auto !important;
        padding-bottom: 10vw !important;
    }

    .mb-text-container {
        padding-top: 10vw;
        margin-left: 7vw;
    }

    .mb-title {
        width: 65%;
    }
    
    .mb-text {
        width:50%;
    }



    .commit-btn {
        padding: 12px 28px 12px 28px;
    }

    .compassion-container {
        height: 45vw;
    }

    .cc-text {
        width: 80%;
    }

}


@media (max-width:768px) {

    .universal-gap {
        margin-bottom: 25px;
    }

    /* .main-banner {
        height: 67vw;
    } */




    .compassion-container {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .cc-title {
        font-size:32px;
    }

    .cc-text {
        font-size:16px;
        width: 90%;
    }

}

