.contact-container {
  background-color: #DBDBDB;
  padding-top: 150px;
  padding-bottom: 50px;
}

.c-title {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(48px, 3.75vw, 72px);
  font-weight: 700;
  text-align: center;
  padding-bottom: 30px;
}


.c-text {
  font-size: clamp(20px, 1.5vw, 28px);
  font-family: "Avenir", sans-serif;
  line-height: 1.4;
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: center;
  width: 100%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px; /* Adjust the max-width as needed */
    margin: 0 auto;
    padding: 20px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .column {
    flex: 1;
    padding:0 15px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    font-size: 19px;
    width: 100%; /* Adjust this width as needed */
    padding: 13px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .divider {
    text-align: center;
    font-weight: bold;
    margin: 1px 0;
  }
  
  .big-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    padding-bottom: 50px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #EE3E2F;
    color: white;
    border: none;
    border-radius: 8px;
    font-family: "Open Sans", sans-serif;
    font-size: 19px;
    font-weight: 5 00;
    padding: 15px 35px 15px 35px;
    margin-top: 30px;
    margin-left: 15px;
    cursor: pointer;
  }

  button:hover {
    background-color: #d1291a;
  }

  @media (max-width: 768px) {

    .contact-container {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .c-title {
      font-size: 36px;
    }

    .c-text {
      font-size: 18px;
    }

    div br {
      display: none;
    }

    .c-text {
      width: 90%;
    }

    .column {
      flex: 1;
      padding:0 10px;
    }

    .row {
      margin-bottom: 10px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select,
    textarea {
      font-size: 14px;
      width: 100%; 
      padding: 10px;
      margin: 5px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      margin-top: 10px;
      margin: 0 auto;
    }
    
  }