
.footer-container {
    background-color: black;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container img {
    margin: 15px
}

.copyright {
    background-color: black;
    color: #EF172F;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    text-align: center;
    margin-top: -20px;
    padding-bottom: 60px;

}

@media (max-width: 768px) {
    .divider {
        display: none;
    }

    .footer-container {
        flex-direction: column;
        padding-top: 10px;
        height: 240px;
    }

    .footer-container img {
        margin: 20px
    }

    .copyright {
        margin-top: 0px;
    }

}