

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    height: 102px;
    width: 100%;
}

.link-text {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from the parent */
}

.logo {
    font-size: 50px;
    color: white;
    display: flex;
    align-items: center;
    margin-left:20vw;
}

.menu-container {
    display: flex;
    /* position: fixed; */
    /* right:20vw; */
    color:white;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin-right: 14vw;
}

.menu-container.show {
    display: flex;
    flex-direction: column; /* Display items vertically for mobile */
    position: absolute;
    top: 100px; /* Adjust this value to control the distance from the top */
    right: 0;
    background-color: #000000;
    width: 100%;
  }

.menu-item {
    padding: 0 35px 0 35px;
}

.menu-icon {
    display: none; /* Hide it by default for larger screens */
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }

  .bar {
    width: 30px;
    height: 4px;
    background-color: #fff; /* Color of the hamburger bars */
    margin: 6px 0 6px;
    transition: 0.4s;
  }

@media (max-width:1100px) {
    .logo {
        margin-left: 11vw;
    }

    .menu-container {
        margin-right: 8vw;
    }
}

@media (max-width:900px) {
    .logo {
        margin-left: 7vw;
    }

    .menu-icon {
        display: block; /* Show it for smaller screens */
        margin-right: 9vw;
      }
    
      .menu-container {
        display: none; /* Hide the menu items by default for smaller screens */
        margin-right: 0;
      }
    
      .menu-container.show .menu-item{
        display: flex; /* Show the menu items when the icon is clicked */
        padding: 20px 0px;
        margin:0 auto;

      }
}

